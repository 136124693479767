@import "./src/assets/styles/scss/variables.scss";
@import "./src/assets/styles/scss/mixins.scss";

.btn-submit {
  margin-top: 15px;
  width: 100%;
  @include font-family($font-opensans, bold);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}
