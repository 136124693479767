// Modal
$border-radius-modal: 10px;
$box-shadow-modal: 0 3px 5px 0 rgba(0,0,0,0.35);

// Override bootstrap/reboot.scss hover with an invalid color value
$link-hover-color: auto;

// static heights
$nav-height: 64px;
$tabbar-height: 51px;
$mobile-app-navbar-height: 44px;
$footer-height-xs: 268px;
$footer-height-lg: 162px;
$save-button-height: 70px;
$side-nav-width: 64px;

$text-shadow-primary: 0 1px 1px rgba(0,0,0,0.20);

$tile-border-radius: 5px;
