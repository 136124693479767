@import './src/assets/styles/scss/variables.scss';
@import './src/assets/styles/scss/mixins.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
}

.position-center {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.content {
  @include size(100%, 100vh);
  margin-left: -$nav-height;
  text-align: center;
  display: flex;
}