// OpenSans Fonts
// Uncomment what you need to use for it to be loaded
@font-face {
  font-family: 'OpenSans-Bold';
  font-weight: 700;

  src: url('../../../fonts/OpenSans/OpenSans-Bold.ttf');
}
// @font-face {
//   font-family: 'OpenSans-BoldItalic';
//   font-weight: 700;
//   font-style: italic;
//
//   src: url('../../../fonts/OpenSans/OpenSans-BoldItalic.ttf');
// }
@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-weight: 800;
  
  src: url('../../../fonts/OpenSans/OpenSans-ExtraBold.ttf');
}
// @font-face {
//   font-family: 'OpenSans-Italic';
//   font-weight: 400;
//   font-style: italic;
//
//   src: url('../../../fonts/OpenSans/OpenSans-Italic.ttf');
// }
@font-face {
    font-family: 'OpenSans-Light';
    font-weight: 300;

    src: url('../../../fonts/OpenSans/OpenSans-Light.ttf');
}
@font-face {
    font-family: 'OpenSans-Regular';
    font-weight: 400;

    src: url('../../../fonts/OpenSans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'OpenSans-Semibold';
  font-weight: 600;

  src: url('../../../fonts/OpenSans/OpenSans-Semibold.ttf');
}
