@import "./src/assets/styles/scss/variables.scss";
@import "./src/assets/styles/scss/mixins.scss";

.row {
  display: flex;
  justify-content: flex-start;
  padding: 5px 30px;
  width: 100%;

  &_alternate {
    @extend .row;
    background-color: $color-claybackground;
  }

  &_header {
    @extend .row;
    padding: 0 30px;
    margin: -8px 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
  }
}

// General Column Styles
.column {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
}

// Individual Column Styles
.id {
  @include reset-element();
  @extend .column;
  width: 50px;
  padding-top: 4px;
  cursor: pointer;
  padding-right: 10px;
}

.flags {
  @extend .column;
  width: 140px;
  padding-left: 2px;
  margin-top: 3px;
}

.title, .title_header, .correct_title_to {
  @extend .column;
  width: 150px;
}

.title {
  padding-top: 4px;
}

.emoji, .emoji_header {
  @extend .column;
  width: 54px;
}

.is_smart_checkbox, .is_smart_checkbox_header {
  @extend .emoji;
}

.emoji__inputfield {
  width: 48px;
  height: 21px;
  margin: 0.25em 0.5em 0.25em 0;
}

.categories,
.synonyms {
  @extend .column;
  width: 300px;
}

.tags_with_this_category {
  @extend .column;
  // screen width minus columns on the left width
  max-width: calc(100vw - 450px);
}

.timestamp {
  @extend .column;
  width: 120px;
}

// Other Styles
button.btn-flag{
  cursor: pointer;
  margin-top: 3px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.tag {
  display: inline-flex;
  align-items: center;
  margin: 0.25em 0.5em 0.25em 0;
  &_plus {
    background-color: #fff;
    border-style: dashed;
    margin: 0.25em 0.5em 0.25em 0;
  }
}
.tag-icon {
  vertical-align: middle;
}

.rtl {
  direction: rtl;
}
