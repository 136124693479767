@import "./src/assets/styles/scss/variables.scss";
@import "./src/assets/styles/scss/mixins.scss";
$gutter-spacing: 10px;
$gutter-spacing-middle: 10px;

$gutter-spacing-sm: 15px;
$gutter-spacing-middle-sm: 30px;

@mixin input-number-hide-arrows() {
  /* For Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin spacing-right($spacing, $spacing-middle) {
  margin-right: $spacing;
  &:nth-child(3) {
    margin-right: $spacing-middle;
  }
  &:last-child {
    margin-right: 0;
  }
}

.container {
  &>input {
    @include size(44px, 52px);
    background-color: $color-claybackground;
    border: 1px solid $color-claydivider;
    border-radius: 5px;
    /* text */
    color: $color-black;
    @include font-family($font-opensans, bold);
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    caret-color: $color-blue;
    @include input-number-hide-arrows();
    @include outline-transparent();
    @include spacing-right($gutter-spacing, $gutter-spacing-middle);
    @include media-breakpoint-up(sm) {
      @include spacing-right($gutter-spacing-sm, $gutter-spacing-middle-sm);
    }
  }
}

.error{
  padding: 10px 0 0;
}