@import "./variables.scss";
@import "./mixins.scss";

@include media-breakpoint-up(lg) {  
  .text-align-right-sm {
    text-align: right;
  }
}

.no-padding {
  padding: 0;
}

.ptr{
  cursor: pointer;
}

.display-block{
  display: block;
}

.display-inline-block{
  display: inline-block;
}

.display-none{
  display: none;
}

.of-hidden{
  overflow: hidden;
  touch-action: none;
}

.full-width{
  width: 100%;
}

.flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bold{
  @include font-family($font-opensans, bold);
}
