@import './src/assets/styles/scss/variables.scss';
@import './src/assets/styles/scss/mixins.scss';

.input{
  @include reset-element();
  // -webkit-appearance: none;
  &:hover, &:focus{
    outline: $color-transparent;
  }
}
