.emoji__inputfield {
  width: 48px;
  height: 21px;
  margin: 0.25em 0.5em 0.25em 0;
}

.tag {
  &-uncommited {
    border-color: orange;
  }
}
.tag-icon {
  vertical-align: middle;
}
