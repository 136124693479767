// Color pallete
$color-blue: #5A99FF;
$color-red: #FE376C;
$color-signalgreen: #4CD964;
$color-green: #85DF68;
$color-yellow: #FFA000;
$color-mediumyellow: #FFAF00;
$color-brightyellow: #FFCC00;
$color-backgroundgray: #E7E7E7;
$color-lightbackgroundgray:#F2F2F2;
$color-superdarkgray: #333333;
$color-darkergray: #5C5C5C;
$color-darkgray: #686868;
$color-buttongray: #F0F0F0;
$color-superlightgray: #FBFBFB;
$color-lightergray: #D9D9D9;
$color-lightgray: #9DA1A9;
$color-mediumgray: #82898D;
$color-gray: #BDC1C9;
$color-darkblue: #34495E;
$color-darkbluemedium: #4A647E;
$color-blueshadow: #487ACC;
$color-darkred: #A93050;
$color-white: #FFFFFF;
$color-black: #000000;
$color-claybackground: #F7F7F7;
$color-claydivider: #EDEDED;
$color-lighterblackshadow: rgba(0, 0, 0, 0.1);
$color-lightblackshadow: rgba(0, 0, 0, 0.15);
// New colors
$color-almostblack: #111111;
$color-redpink: #FF0181;

// Web
$color-lightbackgroundgrayblue:#EEF1F5;
$color-transparent: rgba(255, 255, 255, 0);
// 3rd party brands
$color-brand-twitter: #00ABF0;
$color-brand-facebook: #3B5A98;
